import { MainMobileHeaderItem, SecondaryMobileHeaderItem, MenuSettings } from "./categoryHeaderMutamWrapper";
import { categoryObj } from "../../../../ynet1280/videoGalleryVerticalWidget/components/videoGalleryVerticalMutamComponenta";
import { CategoryHeaderMutamSideMenu } from "./categoryHeaderMutamSideMenu";
import { useState } from "react"
import { SimpleLinkRenderer } from "../../../../../commonComponents/inline/hyperLink";

interface CategoryHeaderMutamProps {
    mainItems: MainMobileHeaderItem[]
    secondaryItems: SecondaryMobileHeaderItem[]
    category: categoryObj
    menuSettings: MenuSettings
}

export const CategoryHeaderMutam = (props: CategoryHeaderMutamProps) => {

    const { mainItems, secondaryItems, category, menuSettings } = props
    const [showSideMenu, setShowSideMenu] = useState<boolean>(false)
    let selectedId = category ? category.id : "";
    const selectedCategory = mainItems.find(item => item.categoryId === selectedId) || secondaryItems.find(item => item.categoryId === selectedId)
    const onSideMenuBtnClick = () => {
        setShowSideMenu(!showSideMenu)
    }

    return (
        <div className="category-header-mutam" style={{ backgroundColor: selectedCategory && selectedCategory.bgColor ? selectedCategory.bgColor : "#d01e25" }}>
            <CategoryHeaderMutamSideMenu
                mainItems={mainItems}
                secondaryItems={secondaryItems}
                showSideMenu={showSideMenu}
                menuSettings={menuSettings}
            />

            <div className="menu-logo side-menu-logo" onClick={onSideMenuBtnClick}></div>

            <div className="heder-title">
                {selectedCategory && selectedCategory.categoryId!=="8" && selectedCategory.title ?
                    selectedCategory.title
                    :
                    <div className="menu-logo calcalist-main-logo"></div>
                }
            </div>

            <div className="header-logo">
                {selectedCategory ?
                    <SimpleLinkRenderer href="https://m.calcalist.co.il/Main.aspx" title="כלכליסט עמוד הבית">
                        <div className="menu-logo calcalist-logo"></div>
                    </SimpleLinkRenderer>
                    :
                    <SimpleLinkRenderer href="https://onelink.to/jetqkv" title="הורדת אפליקציית כלכליסט">
                        <div className="menu-logo download-app-logo"></div>
                    </SimpleLinkRenderer>
                }
            </div>
        </div>
    )
}

CategoryHeaderMutam.siteScriptName = 'CategoryHeaderMutam'
