require("./ctech_calcalist_site_calcalistComponentaShareMenu.less")
require("./ctech_site_calcalistComponentaShareMenu-mobile.less")

import { openFacebookShare, openTwitterShare, openMailShare, analyticsPushFunc, openLinkedinShare, openWhatsAppShare } from "../../widgetsUtils"
import { isWcmEditor } from "config/serverConfig"
import { SiteSettings, SiteSettingsHOC } from "../../../global/components/siteSettingsHOC";

interface CalcalistComponentaShareMenuState {
    isButtonsVisible: boolean
}
interface shareMenuProps {
    url: string
    title?: string
    isLTR?: boolean
    isArticle?: boolean
}

export class CalcalistShareMenu extends React.Component<shareMenuProps, CalcalistComponentaShareMenuState>{
    public static siteScriptName = "CalcalistShareMenu";
    constructor(props: shareMenuProps) {
        super(props)
        this.state = {
            isButtonsVisible: false,

        }
    }
    private toggleMenu = () => {
        this.setState({
            isButtonsVisible: !this.state.isButtonsVisible
        })
    }
    render() {
        const { isButtonsVisible } = this.state
        const { url, title, isLTR, isArticle } = this.props
        const isVisible = isButtonsVisible ? "fadeIn" : ""
        return (
            <div className="ShareMenu">
                <div className={`shareBtnsBox ${isVisible}`}>
                    <button onClick={() => !isWcmEditor() ? openFacebookShare(url, title, () => analyticsPushFunc("Facebook", "Article Main Image", title), "Article Main Image") : null} className="calcalistShare fb" />
                    <button onClick={() => !isWcmEditor() ? openTwitterShare(url, () => analyticsPushFunc("Twitter", "Article Main Image", title), "Article Main Image") : null} className="calcalistShare tw" />
                    {isArticle && <button onClick={() => !isWcmEditor() ? openMailShare(title, "כלכליסט", url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image") : null} className="calcalistShare mail" />}
                    {isLTR && <button onClick={() => !isWcmEditor() ? openLinkedinShare(url, () => analyticsPushFunc("Linkedin", "Article Main Image", title)) : null} className="calcalistShare li" />}
                    {isLTR && <button onClick={() => !isWcmEditor() ? openWhatsAppShare(url, () => analyticsPushFunc("Mail", "Article Main Image", title), "Article Main Image") : null} className="calcalistShare wa" />}
                </div>
                <button className={`toggleShareButtons ${isVisible}`} onClick={this.toggleMenu} />
            </div>
        )
    }

}

