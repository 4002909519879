import { VideoSettingsData, LinkedItemType, ComponentaIconType, IFieldDisplayData } from "widgets/commonDataObjects/interfaces"
import { SlotIconDisplay } from "widgets/commonComponents/inline/slotIconDisplay/slotIconDisplay"
import { SiteItemData, SiteMediaData, SiteImageData, PageComponentaContext } from "widgets/interfaces"
import { SiteMedia } from "../../../../siteWidgets/siteMedia";
import { HyperLinkBuilder } from "widgets/commonComponents/inline/hyperLink"
import { isHavakVisible, PayWallArctilceIcon } from "widgets/commonComponents/inline/payWallHavak/payWallHavak"
import { DateDisplay } from "../../../../commonComponents/inline/dateDisplay/dateDisplay"
import { MediaContentTypes } from "constants/contentTypes"
import { RenderReactAtSite } from "widgets/commonComponents/inline/renderReactAtSite"
import { MutamMarketingSign } from "../../../../commonComponents/inline/marketingSign/mutamMarketingSign"
import { SiteSimpleImage } from "widgets/commonComponents/inline/siteSimpleImage"
import { ArticleData } from "articlePage/stateInterfaces"
import { SiteMediaMobileWrapper } from "../../../../siteWidgets/siteMediaMutamWrapper";

interface MultiImagesFrontProps {
    imageWidth: number
    imageHeight: number
    item?: SiteItemData
    videoSettings?: VideoSettingsData
    fieldsDisplaySettings?: IFieldDisplayData
    titleColor: string
    isYplusComp?: boolean
    getArticle(articleId: string): ArticleData
    displayType: string
    index: number
    isSpecial?: boolean
    isImageOnly: boolean
}

export class MultiImagesItemFrontMobile extends React.Component<MultiImagesFrontProps & Partial<PageComponentaContext>>{
    private getIcon = (icon: ComponentaIconType, hasVideo: boolean) => {
        if (icon === "automatic" && hasVideo) return "video"
        return icon;
    }
    public render() {
        const { imageWidth, item, imageHeight, videoSettings, fieldsDisplaySettings, titleColor, isYplusComp, getArticle, displayType, index, isSpecial, isImageOnly } = this.props
        let emptyMedia: SiteMediaData = { url: "", mediaType: MediaContentTypes.MEDIA_IMAGE, title: "", className: "", alt: "", ariaHidden: true } as SiteImageData
        const titleStyle = { color: titleColor }
        const icon = item.icon ? item.icon : "automatic"
        const showUpInfoArea = fieldsDisplaySettings.isChannelNameVisible
        const authorImage = getArticle(item.itemId).firstAuthorImage
        const isSpecialBigItem = displayType === "MultiArticleImages" && index === 0;
        const isSmallItem = isSpecial && !isSpecialBigItem;
        const isPlus = isHavakVisible({ premiumExpireDays: item.premiumExpireDays, dateUpdatedOnSite: item.dateUpdatedOnSite }) && fieldsDisplaySettings.isMobileYplusLogoVisible;

        return (
            <div className="slotView">
                <div className="mediaArea" data-tb-thumbnail='' style={{ position: !isSmallItem && !isPlus ? "relative" : "unset" }}>
                   <PayWallArctilceIcon isVisible={fieldsDisplaySettings.isMobileYplusLogoVisible} premiumExpireDays={item.premiumExpireDays} dateUpdatedOnSite={item.dateUpdatedOnSite} className={`onImage`} />

                    <div className="MediaCarousel">
                        <RenderReactAtSite>
                            <SiteMediaMobileWrapper
                                data={item.media !== undefined ? item.media : emptyMedia}
                                width={imageWidth}
                                height={imageHeight}
                                videoSettings={videoSettings}
                                itemLinkUrl={item.titleLink}
                                isImageLinkable={true}
                                linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink} />
                        </RenderReactAtSite>
                       
                        <SlotIconDisplay itemId={item.itemId} icon={this.getIcon(icon, item.hasVideo)} iconClassName="medium" isIconVisible={item.hasVideo || icon === "video" || icon === "automatic"} />
                        {!isPlus &&
                            !item.isMarketingContent && item.redLabelText && item.redLabelText.length > 0 && <span className={`basicLabel labelText ${item.textPlace}`}>{item.redLabelText}</span>}
                    </div>

                    {!isSmallItem && !isPlus && item.isMarketingContent &&
                        <RenderReactAtSite>
                            <MutamMarketingSign isSmallItem={false} marketingContentText={item.marketingContentText} />
                        </RenderReactAtSite>}

                </div>

                {!isImageOnly && <div className="textArea">
                    {showUpInfoArea &&
                        <div className="upInfoArea">
                            {fieldsDisplaySettings.isChannelNameVisible && item.subchannelName !== null && item.subchannelName}
                        </div>}

                    <div className="slotRoofTitle" >{item.roofTitle}</div>

                    {fieldsDisplaySettings.isTitleVisible &&
                        <div className={`slotTitle medium ${item.isCommertial ? "commertialTitle" : ""}`}>
                            <HyperLinkBuilder
                                href={item.titleLink}
                                linkTarget={item.titleTargetLink !== undefined && item.titleTargetLink}
                                children={item.title}
                                isDataTbLink >
                                <div dangerouslySetInnerHTML={{ __html: item.title }} style={titleStyle} data-tb-title />
                            </HyperLinkBuilder>
                        </div>}

                    {(!item.isYplusItem || !isYplusComp) &&
                        <div className="moreDetails">
                            {displayType === "MultiArticleImages" && authorImage && <div className="authorImage"><SiteSimpleImage src={authorImage} /></div>}
                            {fieldsDisplaySettings.isAuthorVisible && <span className="authorInfo" data-tb-author>{item.author}</span>}
                            {fieldsDisplaySettings.isAuthorVisible && fieldsDisplaySettings.isDateVisible && item.author.length > 0 && item.itemType === LinkedItemType.ARTICLE && <span className="separator">|</span>}
                            {fieldsDisplaySettings.isDateVisible && item.itemType === LinkedItemType.ARTICLE && <DateDisplay date={item.dateUpdatedOnSite} isTbDate />}
                        </div>}
                </div>}
                {isSmallItem && !isHavakVisible({ premiumExpireDays: item.premiumExpireDays, dateUpdatedOnSite: item.dateUpdatedOnSite }) && item.isMarketingContent &&
                    <RenderReactAtSite>
                        <MutamMarketingSign isSmallItem={true} marketingContentText={item.marketingContentText} />
                    </RenderReactAtSite>}
            </div>
        )
    }
}