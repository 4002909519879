export const placeHeaderBelowTitle = () => {
    const articleHeader = document.querySelector('.calcalistArticleHeader') as HTMLElement;
    const title = document.querySelector('.calcalist-article-blog-header') as HTMLElement;
    const titleRect = title.getBoundingClientRect();
    const articleBody = document.querySelector('.article-body-calcalist') as HTMLElement;

    const blogArticle = document.querySelector('.blog_article') as HTMLElement;
    blogArticle.appendChild(articleHeader);

    // Now you can position it absolutely relative to the viewport
    articleHeader.style.position = 'absolute';

    // Calculate and set the right position
    const bodyRect = blogArticle.getBoundingClientRect();
    const articleBodyRect = articleBody.getBoundingClientRect();
    // This calculation assumes the articleBody is not right-aligned in the viewport.
    // Adjust this calculation if you have a specific design in mind.
    const rightPosition = (bodyRect.right - articleBodyRect.right) / 2;
    articleHeader.style.display = 'flex';
    articleHeader.style.right = `${rightPosition - articleHeader.clientWidth / 2}px`;

    // Position it below the title
    articleHeader.style.top = `${titleRect.bottom}px`;
}

export const setTitleFullWidth = () => {
    // detach the title from its parent
    const title = document.querySelector('.article-blog-header-calcalist') as HTMLElement;
    const titleParent = title.parentElement as HTMLElement;
    titleParent.removeChild(title);
    // append it to .blog_article. insert before .RelativeElementsContainer 
    const blogArticle = document.querySelector('.blog_article') as HTMLElement;
    const relativeElementsContainer = document.querySelector('.RelativeElementsContainer') as HTMLElement;
    blogArticle.insertBefore(title, relativeElementsContainer);
    // set the title to full width
    title.style.width = '100%';
}
