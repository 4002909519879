import { SiteYPlusLoadMoreData } from "./YplusLoadMoreView";
import { YPlusLoadMoreItemSlot } from "./yplusLoadMoreItemSlot";

interface YPlusLoadMoreListProps {
    itemList: SiteYPlusLoadMoreData[]
    height: number
    dataLength: number
    isCategoryVisible?: boolean
    ver?: number
}

export class YPlusLoadMoreList extends React.Component<YPlusLoadMoreListProps, {}>{
    public render() {
        const { itemList } = this.props
        return (
            <div className="slotList" >
                {itemList.map((item, i) =>
                    <YPlusLoadMoreItemSlot
                        key={i}
                        article={item}
                        {...this.props}
                    />)}
            </div>
        )
    }
}