import { ArticleExtraData } from "../../../../articlePage/stateInterfaces"
import { YPlusLoadMoreView } from "./YplusLoadMoreView"
import * as axios from "axios"
import { Loader } from "./loader/loader"
import { addAxiosDateTransformerSettings } from "../../../../utils/network/axiosDateTransformer";

//const NUMBER_OF_ITEMS_PER_LOAD = 12
const MAX_NUMBER_OF_ITEMS = 240

interface YplusLoadMoreProps {
    categoryId: string
    height?: number
    componentaId?: string
    showCategoryLabel?: boolean
    loadFirstPage?: boolean
    firstPageArticles?: ArticleExtraData[]
    ver?: number
    numberOfItems: number
}

interface YplusLoadMoreState {
    articles: ArticleExtraData[]
    pageNumber: number
    showLoader: boolean
    showButton: boolean
}

export class SiteYplusLoadMoreComponenta extends React.Component<YplusLoadMoreProps, YplusLoadMoreState> {
    public static siteScriptName = "SiteYplusLoadMoreComponenta"

    constructor(props) {
        super(props)
        this.state = {
            articles: [],
            pageNumber: 0,
            showLoader: false,
            showButton: true
        }
    }

    componentDidMount() {
        const { categoryId, loadFirstPage, } = this.props;
        if (categoryId && loadFirstPage) {
            this.getArticles(true)
        }
    }

    private showHIdeButton = (newArticles: ArticleExtraData[]) => {
        const { showLoader: loader, articles } = this.state;

        const showButton = !loader &&
            newArticles &&
            !(articles.length % this.props.numberOfItems) &&
            articles.length < MAX_NUMBER_OF_ITEMS;

        this.setState({ showButton })
    }

    private getArticles = (loadFirstPageOnMount: boolean = false) => {
        const { componentaId, firstPageArticles } = this.props
        const { pageNumber, articles } = this.state
        const axiosInstance = axios.create(addAxiosDateTransformerSettings());
        this.setState({ showLoader: true, showButton: false })

        if (loadFirstPageOnMount) {
            this.setState(
                {
                    articles: firstPageArticles,
                    pageNumber: pageNumber + 1,
                    showLoader: false,
                    showButton: true
                }
            );
        }


        axiosInstance.get(`/iphone/json/api/premium_auto_articles/${componentaId}/${pageNumber}`)
            .then((res: any) => {
                const newArticles = res.data.data as ArticleExtraData[];

                this.setState(
                    {
                        articles: [...articles, ...newArticles],
                        pageNumber: pageNumber + 1,
                        showLoader: false,
                    }
                );
                if (typeof (window) !== "undefined") {
                    window.AddBanner && window.AddBanner();
                }
                this.showHIdeButton(newArticles)
            })
            .catch(err => console.error("error geting headline data:", err))
    }

    private handleLoadMoreClick = () => {
        this.getArticles()
        this.analyticsLoadMorePushFunc()
    }

    private analyticsLoadMorePushFunc = () => {
        if (typeof window !== "undefined") {
            window.dataLayer = window.dataLayer || []
            window.dataLayer.push({
                event: 'Navigation_Events',
                Category: 'Ynet+ - Navigation',
                Action: 'Load More Articles',
                Label: 'Load More Articles'
            });
        }
    }

    public render() {
        const { height, showCategoryLabel, ver } = this.props
        const { articles, showLoader: loader, showButton } = this.state;
        const verClass = ver && ver > 1 ? "ver2" : ""

        return (
            <div className={`YPlusLoadMoreComponenta ${verClass}`}>
                <YPlusLoadMoreView loader={loader} isCategoryVisible={showCategoryLabel} height={height} articles={articles} ver={ver} />
                <div className="ButtonAndLoaderContainer">
                    {loader && <Loader />}
                    {showButton && <div className={"loadMoreArticlesButton"} onClick={() => this.handleLoadMoreClick()} >טען עוד כתבות</div>}
                </div>
            </div >
        )
    }
}