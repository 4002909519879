import { SiteSimpleImage } from "../../../../commonComponents/inline/siteSimpleImage";

interface ImageAndCaptionMutamItemProps {
    title: string
    subtitle: string
    promotionImage: string
}

interface ImageAndCaptionMutamItemState {
    backUrl:string
}

export class ImageAndCaptionMutamItem extends React.Component<ImageAndCaptionMutamItemProps, ImageAndCaptionMutamItemState>{
    public static siteScriptName = 'CalcalistImageAndCaptionMutamComponenta';
    constructor(props) {
        super(props)
        this.state = {
            backUrl: "",
        }
    }
    componentDidMount() {
        this.onBackUrlChange();
    }
    
    public onBackUrlChange = () => {
        this.setState({backUrl: window && window.document.referrer ? "javascript:history.back()" : "https://www.ynet.co.il"});
    }

    render() {
        const { title, subtitle, promotionImage } = this.props;
        const { backUrl } = this.state;

        return (
            <div className="imageAndCaptionMutamItemSlot">
                <div className="imageWrapper">
                    <SiteSimpleImage className="img" src={promotionImage} alt={title}/>
                </div>
                <div className="textDiv">
                    <div className="title">{title}</div>
                    <div className="subtitle">{subtitle}</div>
                </div>
                <h2 className="linkWrapper">
                    <a href={backUrl} className="arrowTo" title="back"/>
                </h2>
            </div>
        )
    }
}